const baseUrl = 'wl-soma-dev-external-partner.azurewebsites.net';
// const baseUrl = 'wl-soma-prod-external-partner.azurewebsites.net';

const authGateway = 'apim-dev.somavantagens.com.br/auth';
// const authGateway = 'soma-prod-api-auth.azurewebsites.net';

const wlGateway = 'wl-soma-dev-api-participant.azurewebsites.net';
// const wlGateway = 'wl-soma-prod-api-participant.azurewebsites.net';

export const GATEWAY_SOMA = `https://${baseUrl}/v1`;
export const GATEWAY_SOMA_WL = `https://${wlGateway}/v1`;
export const GATEWAY_SOMA_AUTH = `https://${authGateway}/v1`;

export const environment = {
  production: false,
  ENV_NAME: 'default',
  TOKEN_BASE: `${GATEWAY_SOMA_AUTH}/tokens`,
  REPORT_BASE: `${GATEWAY_SOMA}/report`,
  REWARD_RESERVE_BASE: `${GATEWAY_SOMA}/reward/reserve`,
  STATUS_BASE: `${GATEWAY_SOMA}/status`,
  ENROLLMENT_BASE: `/enrollment`,
  REWARDS_BASE: `${GATEWAY_SOMA_WL}/rewards`,
  TENANT_BASE: `${GATEWAY_SOMA_WL}/tenants`,
  auth_params: {
    reporterCode: 'YlIGCnNQ81Yv7NgNy3aQ4SZW/MdMOTwCgGIV9mBwbRH0z8S3ZArMxA==',
    xConsumerId: 'f6126946-826c-4fef-8afe-8b841fe6cb37',
    consumerToken: '21F905BE-58CE-4BB8-A868-2EBAB5BFB2F3',
    // reporterCode: '7ExyMF0mrKdQ_k8rNEbZM-mfa6eD6UIGbhLR43LLiDGjAzFuyYEwVQ==',
    // xConsumerId: '49075BDE-989B-4B75-A2F3-03F5D3D6919A',
    // consumerToken: '8E6845C9-DD72-4CE1-B444-12CE60F369BF'
  },
};
